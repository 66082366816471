<div class="container text-center bg-grey">
    <div class="row">
        <h3 class="w-100 page-title d-flex justify-content-center align-items-center flex-wrap">
          <img src="/assets/images/schools/wilsonville.png" width="50" alt="Wilsonville Logo" class="d-none d-md-inline" />
          <span class="mx-2 text-center shrink-text">
            COACHING STAFF
          </span>
          <img src="/assets/images/schools/wilsonville.png" width="50" alt="Wilsonville Logo" class="d-none d-md-inline" />
        </h3>
      </div>
    <div class="row">
        <div class="col-sm-6 col-md-3 col-lg-2" style="text-align: center">
            <img src="assets/images/2023/coaches/2023_wilsonville_football_coach_adam_guenther.jpg" width="150"
                class="rounded img-fluid mw-100" alt="Wilsonville Head Coach Adam Guenther" />
            <br />
            Adam Guenther
            <div class="small">
                Head Coach<br />
                Offensive Coordinator
            </div>
            <br />
            <br />
        </div>
        <div class="col-sm-6 col-md-3 col-lg-2" style="text-align: center">
            <img src="assets/images/2023/coaches/2023_wilsonville_football_coach_dave_stippich.jpg" width="150"
                class="rounded img-fluid mw-100" alt="Coach Dave Stippich" />
            <br />
            Dave Stippich
            <div class="small">
                Assistant Coach<br />
                RB/OLB
            </div>
            <br />
            <br />
        </div>
        <div class="col-sm-6 col-md-3 col-lg-2" style="text-align: center">
            <img src="assets/images/2023/coaches/2023_wilsonville_football_coach_arbie_steele.jpg" width="150"
                class="rounded img-fluid mw-100" alt="Coach Arbie Steele" />
            <br />
            Arbie Steele
            <div class="small">
                Assistant Coach<br />
                O-Line
            </div>
            <br />
            <br />
        </div>
        <div class="col-sm-6 col-md-3 col-lg-2" style="text-align: center">
            <img src="assets/images/2023/coaches/2023_wilsonville_football_coach_ned_walls.jpg" width="150"
                class="rounded img-fluid mw-100" alt="Coach Ned Walls" />
            <br />
            Ned Walls
            <div class="small">
                Assistant Coach
                <br />O-Line/D-Line
            </div>
            <br />
            <br />
        </div>
        <div class="col-sm-6 col-md-3 col-lg-2" style="text-align: center">
            <img src="assets/images/2023/coaches/2021_wilsonville_football_coach matt humphreys.jpg" width="150"
                class="rounded img-fluid mw-100" alt="Coach Matt Humphreys" />
            <br />
            Matt Humphreys
            <div class="small">
                Freshman Head Coach
                <br />Varsity Assistant Coach
            </div>
            <br />
            <br />
        </div>
        <div class="col-sm-6 col-md-3 col-lg-2" style="text-align: center">
            <img src="assets/images/2023/coaches/2023_wilsonville_football_coach_jake_smith.jpg" width="150"
                class="rounded img-fluid mw-100" alt="Coach Jake Smith" />
            <br />
            Jake Smith
            <div class="small">
                Assistant Coach
            </div>
            <br />
            <br />
        </div>
        <div class="col-sm-6 col-md-3 col-lg-2" style="text-align: center">
            <img src="assets/images/2023/coaches/2021_wilsonville_football_coach_eric_frazer.jpg" width="150"
                class="rounded img-fluid mw-100" alt="Coach Eric Frazer" />
            <br />
            Eric Frazer
            <div class="small">
                Assistant Coach
                <br />
            </div>
            <br />
            <br />
        </div>
        <div class="col-sm-6 col-md-3 col-lg-2" style="text-align: center">
            <img src="assets/images/2023/coaches/2023_wilsonville_football_coach_micah hatflield.jpg" width="150"
                class="rounded img-fluid mw-100" alt="Coach Micah Hatfield" />
            <br />
            Micah Hatflield
            <div class="small">
                Assistant Coach
            </div>
            <br />
            <br />
        </div>

        <div class="col-sm-6 col-md-3 col-lg-2" style="text-align: center">
            <img src="assets/images/2024/coaches/silhouette_coach.jpg" width="150" class="rounded img-fluid mw-100"
                alt="Coach Chase Eisenbrandt" />
            <br />
            Chase Eisenbrandt
            <div class="small">
                Assistant Coach
            </div>
            <br />
            <br />
        </div>
        <div class="col-sm-6 col-md-3 col-lg-2" style="text-align: center">
            <img src="assets/images/2023/coaches/2023_wilsonville_football_coach_jake_gibson.jpg" width="150"
                class="rounded img-fluid mw-100" alt="Coach Jake Gibson" />
            <br />
            Jake Gibson
            <div class="small">
                Assistant Coach
            </div>
            <br />
            <br />
        </div>
        <div class="col-sm-6 col-md-3 col-lg-2" style="text-align: center">
            <img src="assets/images/2023/coaches/2023_wilsonville_football_coach_chance_hansen.jpg" width="150"
                class="rounded img-fluid mw-100" alt="Coach Chance Hansen" />
            <br />
            Chance Hansen
            <div class="small">
                Assistant Coach
                <br />
            </div>
            <br />
            <br />
        </div>
        <div class="row">
            <div class="col-sm-6 col-md-3 col-lg-2" style="text-align: center">
                <img src="assets/images/2023/coaches/2023_wilsonville_football_teammanager_neveah_ramirez.jpg"
                    width="150" class="rounded img-fluid mw-100" />
                <br />
                <div class="small">
                    Neveah Ramirez
                    <br />
                    Team Manager
                </div>
                <br />
                <br />
            </div>
            <div class="col-sm-6 col-md-3 col-lg-2" style="text-align: center">
                <img src="assets/images/2023/coaches/2023_wilsonville_football_teammanager_lindsey_luper.jpg"
                    width="150" class="rounded img-fluid mw-100" />
                <br />
                <div class="small">
                    Lindsey Luper
                    <br />
                    Team Manager
                </div>
                <br />
                <br />
            </div>
            <div class="col-sm-6 col-md-3 col-lg-2" style="text-align: center">
                <img src="assets/images/2023/coaches/2023_wilsonville_football_teammanager_kehau_kealoha.jpg"
                    width="150" class="rounded img-fluid mw-100" />
                <br />
                <div class="small">
                    Kehau Kealoha
                    <br />
                    Team Manager
                </div>
                <br />
                <br />
            </div>
            <div class="col-sm-6 col-md-3 col-lg-2" style="text-align: center">
                <img src="assets/images/2023/coaches/2023_wilsonville_football_teammanager_jonah_sandall.jpg"
                    width="150" class="rounded img-fluid mw-100" />
                <br />
                <div class="small">
                    Jonah Sandall
                    <br />
                    Team Manager
                </div>
                <br />
                <br />
            </div>
        </div>
    </div>
</div>