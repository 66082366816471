<div class="container  bg-grey" style="height: 100%">
    <div class="row">
        <h3 class="w-100 page-title d-flex justify-content-center align-items-center flex-wrap">
          <img src="/assets/images/schools/wilsonville.png" width="50" alt="Wilsonville Logo" class="d-none d-md-inline" />
          <span class="mx-2 text-center shrink-text">
            SPONSOR PACKAGES
          </span>
          <img src="/assets/images/schools/wilsonville.png" width="50" alt="Wilsonville Logo" class="d-none d-md-inline" />
        </h3>
      </div>
    <div class="row">
        <!--PLATINUM SPONSORSHIP-->
        <div class="col-sm-12 col-md-6 col-lg-3">
            <div class="bg-white rounded">
                <h3 class="title">
                    PLATINUM
                    <span class="opake-large"> - $5000</span>
                    <span class="opake-small">/yr</span>
                </h3>
                <ul class="plan-features">
                    <li><i class="ion-checkmark"> </i>$2,500 Auction Sponsorship and $2,500 Banner Sponsorship</li>
                    <li><i class="ion-checkmark"> </i>4x6 Sideline Banner displayed at all Varsity home games</li>
                    <li><i class="ion-checkmark"> </i>4 Reserved seats and entry for 4 for all regular season home games
                    </li>
                    <li><i class="ion-checkmark"> </i>Premium Website and WHS Home Game program advertising</li>
                    <li><i class="ion-checkmark"> </i>A social media shoutout on our WHS Wildcat Football channels</li>
                    <li><i class="ion-checkmark"> </i>Inclusion in sponsor thank you poster</li>
                    <li><i class="ion-checkmark"> </i>Tickets to our annual Diamonds and Denim auction dinner</li>
                </ul>
                <br />
                <div class="d-flex justify-content-center">
                    <a class="w-50 btn btn-primary"
                        href="https://wilsonvillefootball.square.site/product/platinum-sponsor/35?cs=true"
                        target="_blank">
                        ORDER
                    </a>
                </div>
                <br />
            </div>
            <br />

        </div>
        <!--TOUCHDOWN SPONSORSHIP -->
        <div class="col-sm-12 col-md-6 col-lg-3">
            <div class="bg-white rounded">
                <h3 class="title">
                    <span style="font-size: 0.9em;">TOUCHDOWN</span>
                    <span class="opake-large"> - $2500</span>
                    <span class="opake-small">/yr</span>
                </h3>
                <ul class="plan-features">
                    <li><i class="ion-checkmark"> </i>4x6 Sideline Banner displayed at all Varsity home games</li>
                    <li><i class="ion-checkmark"> </i>4 Reserved seats and entry for 4 for all regular season home games
                    </li>
                    <li><i class="ion-checkmark"> </i>WHS Home Game Program Advertising</li>
                    <li><i class="ion-checkmark"> </i>A social media shoutout on our WHS Wildcat Football channels</li>
                    <li><i class="ion-checkmark"> </i>Inclusion in sponsor thank you poster</li>
                    <li><i class="ion-checkmark"> </i>Tickets to our annual Diamonds and Denim auction dinner</li>
                </ul>
                <br />
                <div class="d-flex justify-content-center">
                    <a class="w-50 btn btn-primary"
                        href="https://wilsonvillefootball.square.site/product/touchdown-sponsor/34?cs=true"
                        target="_blank">
                        ORDER
                    </a>
                </div>
                <br />
            </div>
            <br />
        </div>
        <!--FIELD GOAL SPONSORSHIP-->
        <div class="col-sm-12 col-md-6 col-lg-3">
            <div class="bg-white rounded">
                <h3 class="title">
                    FIELD GOAL
                    <span class="opake-large"> - $1000</span>
                    <span class="opake-small">/yr</span>
                </h3>
                <ul class="plan-features">
                    <li><i class="ion-checkmark"> </i>3x5 Sideline Banner displayed at all Varsity home games</li>
                    <li><i class="ion-checkmark"> </i>2 Reserved seats for all regular season games</li>
                    <li><i class="ion-checkmark"> </i>A social media shoutout on our WHS Wildcat Football channels</li>
                    <li><i class="ion-checkmark"> </i>Inclusion in sponsor thank you poster</li>
                </ul>
                <br />
                <div class="d-flex justify-content-center">
                    <a class="w-50 btn btn-primary"
                        href="https://wilsonvillefootball.square.site/product/field-goal-sponsor/33?cs=true"
                        target="_blank">
                        ORDER
                    </a>
                </div>
                <br />
            </div>
            <br />
        </div>
        <!--FIRST DOWN SPONSORSHIP-->
        <div class="col-sm-12 col-md-6 col-lg-3">
            <div class="bg-white rounded">
                <h3 class="title">
                    FIRST DOWN
                    <span class="opake-large"> - $500</span>
                    <span class="opake-small">/yr</span>
                </h3>
                <ul class="plan-features">
                    <li><i class="ion-checkmark"> </i>3x5 Sideline Banner displayed at all Varsity home games</li>
                    <li><i class="ion-checkmark"> </i>Inclusion in sponsor thank you poster</li>
                </ul>
                <br />
                <div class="d-flex justify-content-center">
                    <a class="btn btn-primary mx-auto w-50"
                        href="https://wilsonvillefootball.square.site/product/first-down-supporter/32?cs=true"
                        target="_blank">
                        ORDER
                    </a>
                </div>
                <br />
            </div>
        </div>
    </div>
</div>