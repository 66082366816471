import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTableModule } from '@angular/material/table';

import { ISchedule } from '../schedule';
import { ScheduleService } from '../schedule.service';

@Component({
  selector: 'app-varsitygame',
  templateUrl: './varsitygame.component.html',
  styleUrl: './varsitygame.component.scss',
})

export class VarsitygameComponent implements OnInit {
  // applyFilter($event: KeyboardEvent) {
  // throw new Error('Method not implemented.');
  // }
  pageTitle: string = 'Game Schedule';
  errorMessage: any;
  displayedColumns: string[] = ['date', 'opponentSchool', 'score', 'location', 'note'];

  schedule_dataSource = new MatTableDataSource<ISchedule>()

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort

  constructor(
    private scheduleService: ScheduleService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.scheduleService.getVarsityGameSchedule().subscribe({
      next: (schedule_dataSource) => (this.schedule_dataSource.data = schedule_dataSource),
      error: (err) => (this.errorMessage = err),
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event?.target as HTMLInputElement).value;
    this.schedule_dataSource.filter = filterValue.trim().toLowerCase();
  }
  ngAfterViewInit(): void {
    //  this.sort.sort(({ id:'id', start: 'asc'}) as MatSortable);  // sets the default sort order
    this.schedule_dataSource.sort = this.sort;
    this.schedule_dataSource.paginator = this.paginator;
    // //throw new Error('Method not implemented.');
  }

}