import { NgFor } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import {
  CarouselCaptionComponent,
  CarouselComponent,
  CarouselControlComponent,
  CarouselIndicatorsComponent,
  CarouselInnerComponent,
  CarouselItemComponent,
  ThemeDirective
} from '@coreui/angular';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  highlightslides: any[] = new Array(5).fill({ id: -1, src: '', title: '', subtitle: '' });
  sponsorslides: any[] = new Array(5).fill({ id: -1, src: '', title: '', subtitle: '' });

  ngOnInit(): void {
    // Define Highlight photo array
    this.highlightslides[0] = {
      id: 0,
      src: './assets/images/2023/20231124-triplem_5a state football championship game wilsonville vs mountain view_226044.jpg',
      title: 'BREAKING FREE',
      subtitle: '5A State Football Championship game vs Mountain View'
    };
    this.highlightslides[1] = {
      id: 1,
      src: './assets/images/2023/20231124-triplem_5a state football championship game wilsonville vs mountain view_224911.jpg',
      title: 'THE CATCH',
      subtitle: '5A State Football Championship game vs Mountain View'
    };
    this.highlightslides[2] = {
      id: 2,
      src: '/assets/images/2023/20231124-triplem_5a state football championship game wilsonville vs mountain view_220734.jpg',
      title: 'TOUCHDOWN',
      subtitle: '5A State Football Championship game vs Mountain View'
    };
    this.highlightslides[3] = {
      id: 3,
      src: '/assets/images/2023/20231124-triplem_5a state football championship game wilsonville vs mountain view_226313.jpg',
      title: 'DEFENSE',
      subtitle: '5A State Football Championship game vs Mountain View'
    };
    this.highlightslides[4] = {
      id: 4,
      src: './assets/images/2023/20231124 state champions trophy-3d2gnxt.jpg',
      title: 'CHAMPIONS',
      subtitle: '5A State Football Championship game vs Mountain View'
    };
    this.highlightslides[5] = {
      id: 5,
      src: './assets/images/2023/20230901-triplem_wilsonville_58471_tunnel walk in_4x3s.jpg',
      title: 'THE TUNNEL',
      subtitle: ''
    };

    // Define Sponsors array
    this.sponsorslides[0] = {
      id: 0,
      src: '/assets/images/sponsors/tzmedical.png',
      title: 'TZ Medical Inc.',
      subtitle: 'Touchdown Sponsor'
    };
    this.sponsorslides[1] = {
      id: 1,
      src: '/assets/images/sponsors/marquis.png',
      title: 'Marquis',
      subtitle: 'Touchdown Sponsor'
    };
    this.sponsorslides[2] = {
      id: 2,
      src: '/assets/images/sponsors/ruppfamilybuildersdark.png',
      title: 'Rupp Family Builders',
      subtitle: 'Touchdown Sponsor'
    };
    this.sponsorslides[3] = {
      id: 3,
      src: '/assets/images/sponsors/swire-coca-cola.png',
      title: 'Swire Coca-Cola',
      subtitle: 'Touchdown Sponsor'
    };
    this.sponsorslides[4] = {
      id: 4,
      src: '/assets/images/sponsors/westernpartitionsincorporated.png',
      title: 'WPI Builds',
      subtitle: 'Touchdown Sponsor'
    };
    this.sponsorslides[5] = {
      id: 5,
      src: '/assets/images/sponsors/grahamandtooze.jpg',
      title: 'Graham & Tooze Farm Store',
      subtitle: 'Field Goal Sponsor'
    };
    this.sponsorslides[6] = {
      id: 6,
      src: '/assets/images/sponsors/gradynelsonpremierpropertygroup.png',
      title: 'Grady Nelson - Premiere Property Group',
      subtitle: 'Touchdown Sponsor'
    };
    this.sponsorslides[7] = {
      id: 7,
      src: '/assets/images/sponsors/lancessuperiorautoservice.jpg',
      title: "Lance's Superior Auto Services",
      subtitle: 'Field Goal Sponsors'
    };
    this.sponsorslides[8] = {
      id: 8,
      src: '/assets/images/sponsors/statefarmjakepuppo.jpg',
      title: 'Jay Puppo State Farm Insurance',
      subtitle: 'Field Goal Sponsor'
    };
    this.sponsorslides[9] = {
      id: 9,
      src: '/assets/images/sponsors/crowleylandscape.png',
      title: 'Crowley Landscape Management',
      subtitle: 'First Down Sponsor'
    };
    this.sponsorslides[10] = {
      id: 10,
      src: '/assets/images/sponsors/mcconnaughey-real-estate.webp',
      title: 'McConnaughey Real Estate',
      subtitle: 'First Down Sponsor'
    };
    this.sponsorslides[11] = {
      id: 11,
      src: '/assets/images/sponsors/resticorthodontics.jpg',
      title: 'Wilsonville Orthodontics - Dr. Restic',
      subtitle: 'First Down Sponsor'
    };
    this.sponsorslides[12] = {
      id: 12,
      src: '/assets/images/sponsors/wilsonville-carpet-tile.jpg',
      title: 'Wilsonville Carpet and Tile',
      subtitle: 'First Down Sponsor'
    };
    this.sponsorslides[13] = {
      id: 13,
      src: '/assets/images/sponsors/mcdonalds.jpg',
      title: 'McDonalds Greentree Enterprises',
      subtitle: 'First Down Sponsor'
    };
    this.sponsorslides[14] = {
      id: 14,
      src: '/assets/images/sponsors/barrierpestcontrol2.png',
      title: 'Barrier Pest Control',
      subtitle: 'First Down Sponsor'
    };
    this.sponsorslides[15] = {
      id: 15,
      src: '/assets/images/sponsors/laueteamrealestate.jpg',
      title: 'Laue Team Real Estate',
      subtitle: 'First Down Sponsor'
    };
    this.sponsorslides[16] = {
      id: 16,
      src: '/assets/images/sponsors/wentworthchevy.jpg',
      title: 'Wentworth Chevy',
      subtitle: 'First Down Sponsor'
    };
    this.sponsorslides[17] = {
      id: 17,
      src: '/assets/images/sponsors/stafford-beverage-logo.jpg',
      title: 'Stafford Beverage',
      subtitle: 'First Down Sponsor'
    };

  }
}
