import { Component } from '@angular/core';

@Component({
  selector: 'app-sponsorlist',
  templateUrl: './sponsorlist.component.html',
  styleUrl: './sponsorlist.component.scss'
})
export class SponsorlistComponent {
  sponsors = [
    {
      "name": "TZ Medical Inc.",
      "url": "https://www.tzmedical.com",
      "logo": "/assets/images/sponsors/tzmedical.png",
      "sponsorLevel": "Touchdown Sponsor"
    },
    {
      "name": "Marquis",
      "url": "https://www.marquiscompanies.com",
      "logo": "/assets/images/sponsors/marquis.png",
      "sponsorLevel": "Touchdown Sponsor"
    },
    {
      "name": "Rupp Family Builders",
      "url": "https://www.kitchensbyrupp.com",
      "logo": "/assets/images/sponsors/ruppfamilybuildersdark.png",
      "sponsorLevel": "Touchdown Sponsor"
    },
    {
      "name": "Swire Coca-Cola",
      "url": "https://www.swirecc.com",
      "logo": "/assets/images/sponsors/swire-coca-cola.png",
      "sponsorLevel": "Touchdown Sponsor"
    },
    {
      "name": "WPI Builds",
      "url": "https://wpibuilds.com",
      "logo": "/assets/images/sponsors/westernpartitionsincorporated.png",
      "sponsorLevel": "Touchdown Sponsor"
    },
    {
      "name": "Graham & Tooze Farm Store",
      "url": "https://www.grahamandtooze.com",
      "logo": "/assets/images/sponsors/grahamandtooze.jpg",
      "sponsorLevel": "Field Goal Sponsor"
    },
    {
      "name": "Grady Nelson - Premiere Property Group",
      "url": "https://www.gradynelson.com/",
      "logo": "/assets/images/sponsors/gradynelsonpremierpropertygroup.png",
      "sponsorLevel": "Touchdown Sponsor"
    },
    {
      "name": "Lance/'s Superior Auto Services",
      "url": "https://www.lancessuperiorauto.com",
      "logo": "/assets/images/sponsors/lancessuperiorautoservice.jpg",
      "sponsorLevel": "Field Goal Sponsors"
    },
    {
      "name": "Jay Puppo State Farm Insurance",
      "url": "https://www.jaypuppo.com",
      "logo": "/assets/images/sponsors/statefarmjakepuppo.jpg",
      "sponsorLevel": "Field Goal Sponsor"
    },
    {
      "name": "Crowley Landscape Management",
      "url": "https://crowleylandscape.com/",
      "logo": "/assets/images/sponsors/crowleylandscape.png",
      "sponsorLevel": "First Down Sponsor"
    },
    {
      "name": "McCounnaughey Real Estate",
      "url": "https://mcrehomes.com",
      "logo": "/assets/images/sponsors/mcconnaughey-real-estate.webp",
      "sponsorLevel": "First Down Sponsor"
    },
    {
      "name": "Wilsonville Orthodontics - Dr. Restic",
      "url": "https://www.resticortho.com",
      "logo": "/assets/images/sponsors/resticorthodontics.jpg",
      "sponsorLevel": "First Down Sponsor"
    },
    {
      "name": "Wilsonville Carpet and Tile",
      "url": "http://www.wilsonvillecarpetandtile.net/",
      "logo": "/assets/images/sponsors/wilsonville-carpet-tile.jpg",
      "sponsorLevel": "First Down Sponsor"
    },
    {
      "name": "McDonalds Greentree Enterprises",
      "url": "https://www.mcdonalds.com",
      "logo": "/assets/images/sponsors/mcdonalds.jpg",
      "sponsorLevel": "First Down Sponsor"
    },
    {
      "name": "Barrier Pest Control",
      "url": "https://www.barrierpc.com",
      "logo": "/assets/images/sponsors/barrierpestcontrol2.png",
      "sponsorLevel": "First Down Sponsor"
    },
    {
      "name": "Laue Team Real Estate",
      "url": "http://www.thelaueteam.com/",
      "logo": "/assets/images/sponsors/laueteamrealestate.jpg",
      "sponsorLevel": "First Down Sponsor"
    },
    {
      "name": "Wentworth Chevy",
      "url": "https://www.wilsonvillechevrolet.com",
      "logo": "/assets/images/sponsors/wentworthchevy.jpg",
      "sponsorLevel": "First Down Sponsor"
    },
    {
      "name": "Stafford Beverage",
      "url": "http://www.staffordbeverage.com",
      "logo": "/assets/images/sponsors/stafford-beverage-logo.jpg",
      "sponsorLevel": "First Down Sponsor"
    }
  ];
}
