<div class="container bg-grey" style="height: 100%">
  <div class="row">
    <h3 class="w-100 page-title d-flex justify-content-center align-items-center flex-wrap">
      <img src="/assets/images/schools/wilsonville.png" width="50" alt="Wilsonville Logo" class="d-none d-md-inline" />
      <span class="mx-2 text-center shrink-text">
        JV/FRESHMAN GAME SCHEDULE - 2024
      </span>
      <img src="/assets/images/schools/wilsonville.png" width="50" alt="Wilsonville Logo" class="d-none d-md-inline" />
    </h3>
  </div>
  <div class="row">
    <table mat-table [dataSource]="schedule_dataSource" matSort>
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef style="color: white; background-color: #222f3d;">DATE</th>
        <td mat-cell *matCellDef="let element">
          {{ element.date }} &nbsp;{{ element.time }}
        </td>
      </ng-container>
      <ng-container matColumnDef="opponentSchool">
        <th mat-header-cell *matHeaderCellDef style="color: white; background-color: #222f3d;">OPPONENT</th>
        <td mat-cell *matCellDef="let element">
          <img src="/assets/images/schools/{{ element.opponentLogo }}" alt="{{ element.opponentSchool }} logo"
            style="width: 50px; height: 50px" />
            <br/>
            {{ element.opponentSchool }}
        </td>
      </ng-container>
      <ng-container matColumnDef="score">
        <th mat-header-cell *matHeaderCellDef style="color: white; background-color: #222f3d;">SCORE</th>
        <td mat-cell *matCellDef="let element">
          {{ element.winLose }} <br />
          {{ element.score }} &nbsp;
        </td>
      </ng-container>
      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef style="color: white; background-color: #222f3d;">LOCATION</th>
        <td mat-cell *matCellDef="let element">
          <a href="{{ element.locationMap }}" target="_blank">
            <img src="/assets/images/misc/mappin.png" width="10" />
          </a>
          <br />
          <a href="{{ element.locationMap }}" target="_blank">
            {{ element.location }}
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="note">
        <th mat-header-cell *matHeaderCellDef style="color: white; background-color: #222f3d;">NOTE</th>
        <td mat-cell *matCellDef="let element">
          {{ element.note }}
          <br />
          <button type="button" class="btn btn-info btn-sm" data-toggle="modal" style="
              color: white;
              background-color: #336699;
              border-color: #3366cc;
              font-size: small;
              align-content: center;
            ">
            Photos, Articles and Videos
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter</td>
      </tr>
    </table>
    <!-- <mat-paginator [pageSize]="25" [pageSizeOptions]=[10,25,100] [showFirstLastButtons]="true">
</mat-paginator> -->

  </div>
  <br />
</div>