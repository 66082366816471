<div class="container bg-grey h-100 text-center">
    <div class="row">
      <h3 class="w-100 page-title d-flex justify-content-center align-items-center flex-wrap">
        <img src="/assets/images/schools/wilsonville.png" width="50" alt="Wilsonville Logo" class="d-none d-md-inline" />
        <span class="mx-2 shrink-text">JV ROSTER 2024</span>
        <img src="/assets/images/schools/wilsonville.png" width="50" alt="Wilsonville Logo" class="d-none d-md-inline" />
      </h3>
    </div>
  
    <div class="row justify-content-center">
      <img src="assets/images/2023/rosters/froshsoph/2023_wilsonville_football_team_photo_jv_freshman.jpg"
           alt="Wilsonville Wildcat JV" class="img-fluid rounded">
    </div>
  
    <div class="row justify-content-center">
          <table class="table table-bordered table-striped w-100">
            <thead>
              <tr>
                <th>Jersey</th>
                <th>Name</th>
                <th>Position</th>
                <th>Grade</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let player of roster_dataSource.data">
                <td>{{ player.jersey }}</td>
                <td>{{ player.name }}</td>
                <td>{{ player.position }}</td>
                <td>{{ player.grade }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
  