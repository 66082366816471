<div class="container bg-grey" style="height: 100%;">
    <div class="row">
      <h3 class="w-100 page-title d-flex justify-content-center align-items-center flex-wrap">
        <img src="/assets/images/schools/wilsonville.png" width="50" alt="Wilsonville Logo" class="d-none d-md-inline" />
        <span class="mx-2 text-center shrink-text">
          ALUMNI
        </span>
        <img src="/assets/images/schools/wilsonville.png" width="50" alt="Wilsonville Logo" class="d-none d-md-inline" />
      </h3>
    </div>
    <div class="row">
        <div class="col-12 col-md-6 img-container text-center mx-auto">
            <img src="assets/images/alumni/2023-5a-football-state_champions_trophy.jpg" srcset="assets/images/alumni/2023-5a-football-state_champions_trophy_small.jpg 300w, 
                          assets/images/alumni/2023-5a-football-state_champions_trophy.jpg 800w"
                sizes="(max-width: 600px) 100vw, 50vw"
                alt="2023 OSAA 5A Football State Champions" />
            <br />2023 OSAA 5A Football State Champions
        </div>
        <div class="col-12 col-md-6 img-container text-center mx-auto">
            <img src="assets/images/alumni/2004_3a_football_state_champions.jpg" srcset="assets/images/alumni/2004_3a_football_state_champions_small.jpg 300w, 
                          assets/images/alumni/2004_3a_football_state_champions.jpg 800w"
                sizes="(max-width: 600px) 100vw, 50vw" 
                alt="2004 OSAA 3A Football State Champions" />
            <br />2004 OSAA 3A Football State Champions
        </div>
    </div>
      <div class="row">
        <div class="col-sm-12 col-md-4 col-lg-2 img-container text-center mx-auto" *ngFor="let alumni of alumniProfiles">
            <img [src]="'assets/images/alumni/'+alumni.imageCollegeProfile" [alt]="alumni.name" />
            <br />{{ alumni.name }}
            <br /><img [src]="'/assets/images/colleges/'+alumni.college1logo" width=""/>
        </div>
      </div>    
</div>
